export const defaultDataRegister =
// {
//     productHand: 'NEW',
//     productGroup: 'iPhone',
//     acceptCondition: false,
//     walkin: false,
//     job: {
//         type: 'บุคคลทั่วไป'
//     },
//     // type: "REFINANCE",
// }

{
    "productHand": "NEW",
    "productGroup": "iPhone",
    "acceptCondition": false,
    "walkin": false,
    "type": "REFINANCE",
    "profile": {
        "type": "REFINANCE",
        "prefix": "นาย",
        "firstName": "เทสกัน",
        "lastName": "ไอโฟนแลกเงิน",
        "zipcode": "11110",
        "phoneNumber": "0983333222",
        "idCard": "8403274238963",
        "address": "เทส",
        "subdistrict": "บางคูรัด",
        "district": "บางบัวทอง",
        "province": "นนทบุรี",
        "facebook": "gmlgml",
        "phoneSystem": "เติมเงิน",
        "dateOfBirth": "09/09/1991",
    },
    "chatcone": null,
    "product": {
        "model": "iPhone 11",
        "storage": "128GB",
        "color": "Black"
    },
    "installments": 6,
    "interestedBranch": "CENTER",
    "combo": [],
    "statement": {
        "images": [
            {
                "fileName": "ufriend-register/register_cc3hpts5/1720075528196.jpeg",
                "mimeType": "image/jpeg"
            }
        ],
        "type": "3MONTH",
        "month": "เมษายน-มิถุนายน",
        "password": "จิงใจไม่จิงโจ้"
    },
    "proveWork": [],
    "riderAppication": [],
    "governmentOfficial": [],
    "proveOwnBusinessImageFlag": true,
    "proveOwnBusinessImage": [
        {
            "fileName": "ufriend-register/register_wzji4ugu/1720075513143.jpeg",
            "mimeType": "image/jpeg"
        }
    ],
    "salesChannels": "OFFLINE",
    "salesChannelsImage": {
        "images": [
            {
                "fileName": "ufriend-register/register_0lxnaqsu/1720075524761.jpeg",
                "mimeType": "image/jpeg",
                "name": "20240513-140248.jpg",
                "img": "blob:https://dev3-register.ufdev.link/dd4eb785-7e4c-4d34-909a-34b057080332"
            }
        ],
        "link": ""
    },
    "contact": [
        {
            "relationship": "พ่อแม่",
            "firstName": "ห",
            "lastName": "ก",
            "phoneNumber": "0981111110"
        },
        {
            "relationship": "พ่อแม่",
            "firstName": "หห",
            "lastName": "กก",
            "phoneNumber": "0981111111"
        },
        {
            "relationship": "พ่อแม่",
            "firstName": "หหห",
            "lastName": "กกก",
            "phoneNumber": "0981111112"
        }
    ],
    "knownUfriendFrom": {
        "value": "CAR_ADVERTISE",
        "label": "ป้ายโฆษณาติดรถ"
    },
    "othersDetails": ""
}

export const REGISTER_TYPE_TH = {
    INSTALLMENT: "ผ่อน",
    REFINANCE: "แลกเงิน",
};
